import { mapActions, mapGetters, mapMutations } from 'vuex'
import validationMixin from '@/mixins/validation'
import mixins, { toLatin } from '@/mixins/index'
import ImageFile from '@/components/image-file/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue'
import moment from 'moment'

import { difference } from 'lodash'

export default {
  name: 'news-create',
  mixins: [validationMixin, mixins],
  data () {
    return {
      payload: {
        date: new Date().toISOString().substr(0, 10),
        name: '',
        slug: '',
        country: '',
        locale: '',
        status: '',
        picture: '',
        cover: '',
        categories: [],
        tags: [],
        pinned: false
      },
      index: null,
      valid: false,
      openDatepicker: false,
      validationErrors: {},
      disabledBtnTag: true,
      tagsSearch: ''
    }
  },
  components: {
    ImageFile,
    SlugNameInput
  },
  computed: {
    ...mapGetters({
      newsTags: 'newsConfig/newsTags',
      newsTagsLoading: 'newsConfig/newsTagsLoading',
      newsCategories: 'newsConfig/newsCategories',
      newsCategoriesLoading: 'newsConfig/newsCategoriesLoading',
      accessCountries: 'profile/accessCountries',
      profile: 'profile/profile',
      newsGeneral: 'newsGeneral/newsGeneral',
      newsGeneralLoading: 'newsGeneral/newsGeneralLoading',
      newsGeneralUpdateLoading: 'newsGeneral/newsGeneralUpdateLoading',
      newsGeneralCreateLoading: 'newsGeneral/newsGeneralCreateLoading',
      changeTagsLoading: 'newsGeneral/changeTagsLoading',
      changeCategoriesLoading: 'newsGeneral/changeCategoriesLoading',
      quickTagLoading: 'newsConfig/quickTagLoading',
      setting: 'news/setting',

      updateImageLoading: 'newsGeneral/updateImageLoading'
    }),
    computedDateFormatted () {
      return moment(this.payload.date).format('DD.MM.YYYY')
    },
    listLocales () {
      if (this.accessCountries) {
        const currentCountry = this.accessCountries.find(e => e.country_id === this.payload.country)
        if (currentCountry) {
          return currentCountry.locales.data
        } else {
          return []
        }
      }
      return []
    }
  },
  created () {
    if (!this.newsTags) {
      this.fetchTags()
    }
    if (!this.newsCategories) {
      this.fetchCategories()
    }
    this.setting || this.fetchSetting()

    if (!this.$route.name.includes('.create')) {
      if (!this.newsGeneral) {
        this.fetchContent({
          id: this.$route.params.id,
          module: this.$route.name
        }).then(() => {
          this.setData()
        })
      } else {
        this.setData()
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCategories: 'newsConfig/GET_NEWS_CATEGORIES',
      fetchTags: 'newsConfig/GET_NEWS_TAGS',
      fetchSetting: 'news/GET_SETTING',
      fetchContent: 'newsGeneral/GENERAL_GET_DATA',
      update: 'newsGeneral/GENERAL_UPDATE_DATA',
      create: 'newsGeneral/GENERAL_CREATE_DATA',
      addTag: 'newsGeneral/GENERAL_ADD_TAG',
      removeTag: 'newsGeneral/GENERAL_REMOVE_TAG',
      addCategory: 'newsGeneral/GENERAL_ADD_CATEGORY',
      removeCategory: 'newsGeneral/GENERAL_REMOVE_CATEGORY',
      updateImage: 'newsGeneral/UPDATE_IMAGE',
      addQuickTag: 'newsConfig/ADD_QUICK_TAG'
    }),
    ...mapMutations({
      setCountryIds: 'profile/SET_COUNTRY_IDS'
    }),
    updateImageLocal (params) {
      params.append('news_id', this.newsGeneral.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    setData () {
      Object.assign(this.payload, this.newsGeneral)
      this.setCountryIds([this.payload.country_id])
    },
    createRequestPayload () {
      const data = {
        id: this.payload.id,
        country_id: this.payload.country,
        default_locale: this.payload.locale,
        status: this.payload.status,
        published_at: this.payload.date,
        name: this.payload.name,
        slug: this.payload.slug,
        picture: this.payload.picture,
        pinned: this.payload.pinned ? '1' : '0'
      }
      return data
    },
    changeActions (type, add, remove, list) {
      // delete and update -> categories and tags
      if (this.newsGeneral[type] <= 0) {
        this.payload[type] && this.payload[type].forEach(e => {
          add({
            newsId: this.$route.params.id,
            id: e,
            item: list.find(t => t.id === e)
          })
        })
      } else {
        const previewsIds = this.newsGeneral[type].map(e => e.id)
        const selectedIds = this.payload[type].map(e => {
          return e.hasOwnProperty('id') ? e.id : e
        })

        // add
        const differenceForAdd = difference(selectedIds, previewsIds)
        if (differenceForAdd.length > 0) {
          differenceForAdd.forEach(e => {
            add({
              newsId: this.$route.params.id,
              id: e,
              item: list.find(t => t.id === e)
            })
          })
        }

        // remove
        const differenceForRemove = difference(previewsIds, selectedIds)
        if (differenceForRemove.length > 0) {
          differenceForRemove.forEach(e => {
            remove({
              newsId: this.$route.params.id,
              id: e
            })
          })
        }
      }
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (!this.$route.name.includes('.create')) {
          this.update({
            ...this.createRequestPayload(),
            module: this.$route.name
          }).then(() => {
            if (this.$route.name === 'news.general') {
              this.changeActions('tags', this.addTag, this.removeTag, this.newsTags)
              this.changeActions('categories', this.addCategory, this.removeCategory, this.newsCategories)
            }

            this.$toasted.success(this.$t('success_updated'))
          })
        } else {
          this.create({
            ...this.createRequestPayload(),
            module: this.$route.name
          }).then(response => {
            this.$toasted.success(this.$t('success_created'))
            this.$router.replace({
              name: 'news.general',
              params: { id: response.id }
            })
          })
        }
      }
    },
    checkDisableBtnTag (val) {
      if (!val) {
        this.disabledBtnTag = true
        return
      }
      const item = this.newsTags.find(e => e.name === val)
      if (item) {
        this.disabledBtnTag = true
      } else {
        if (this.tagsSearch.length < 3) {
          this.disabledBtnTag = true
        } else {
          this.disabledBtnTag = false
        }
      }
    },
    addQuickTagLocal () {
      const payload = {
        country_id: this.payload.country,
        tag: this.tagsSearch,
        locale: this.payload.locale
      }
      this.addQuickTag(payload).then(response => {
        this.payload.tags.push(response.id)
      })
    }
  }
}
